import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import '../../node_modules/react-owl-carousel2/lib/styles.css';
import {graphql, StaticQuery} from 'gatsby'
import {Container, Row, Col} from "react-bootstrap"

const Slide = ({fluid, children, height}) => (
  <div style={{
    backgroundImage: `url(${fluid.src})`, 
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  }}>
    <Container style={{height}}>
      <Row className="h-100">
        <Col className="py-5 d-flex align-items-center justify-content-center">
          <p style={{
            fontSize: "3rem",
            backgroundColor: "rgba(0,0,0,0.7)",
            color: "#fff",
            padding: "2rem"
          }}>
            {children}
          </p>
        </Col>
      </Row>
    </Container>
  </div>
)

export default class extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      height: "300px"
    }
  }

  componentDidMount() {
    this.setState({height: window.innerHeight * 0.5})
  }

  render() {
    const options = {
      items: 1,
      rewind: true,
      autoplay: true,
      loop: true
    };

    return (
      <StaticQuery
        query={graphql`
          query {
            bg1: file(relativePath: { eq: "1-bg.jpg" }) {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            bg2: file(relativePath: { eq: "2-bg.jpg" }) {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            bg3: file(relativePath: { eq: "3-bg.jpg" }) {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        `}
        render={data => {
          return (
            <OwlCarousel options={options} >
              <Slide fluid={data.bg1.childImageSharp.fluid} height={this.state.height}>Черный металл</Slide>
              <Slide fluid={data.bg2.childImageSharp.fluid} height={this.state.height}>Цветной металл</Slide>
              <Slide fluid={data.bg3.childImageSharp.fluid} height={this.state.height}>Химический анализ сплавов</Slide>
            </OwlCarousel >
          )
        }}
      />

    );
  }
}
